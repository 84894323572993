import React from 'react';
import Axios from 'axios';
import hashIdCoder from 'hashIdCoder';

import { withContext, log } from 'kn-react';
import { SnackbarContext, ThemeContext, PromiseButton, UserRoleContext, Alert, PlanModel, SubscriptionModel, Ability } from 'go-boost-library-react';

import {
  Grid, Typography, Paper, withStyles, Button, Tooltip, List, ListItem,
  ListItemText, ListItemSecondaryAction, IconButton, FormControl, FormLabel,
  FormControlLabel, Switch,
  TextField
} from '@material-ui/core';
import { OpenInNew, Edit } from '@material-ui/icons';
import { fetchCurrentSite } from 'Sites/CompanySites/UnpublishedCurrentSiteAlert/UnpublishedCurrentSiteAlert'
import sitePreviewUrl from 'Sites/sitePreviewUrl';
import CompanySitesProfileModel from 'Sites/CompanySites/CompanySitesProfile/CompanySitesProfileModel';
import CompanySiteDesignModel from 'Sites/CompanySites/SiteDesigns/CompanySiteDesignModel';
import EditByoDomainDialog from './EditByoDomainDialog';
import SearchConsoleTag from 'Sites/CompanySites/SearchConsoleTag/SearchConsoleTag';



class SiteSettings extends React.Component {

  state = {
    companySitesProfile: {},
    companySiteDesigns: [],
    site: null,
    dialogOpen: false,
    byoDomainInfoReceived: true,
    plans: [],
    subscriptions: [],
    subscribedPlans: [],
    ppCustomHTML: '',
    tosCustomHTML: ''
  }


  componentDidMount = () => {
    return this.fetchCompanySitesProfile()
      .then( this.fetchCompanySitesDesigns )
      .then( this.fetchPlans )
      .then( this.fetchSubscriptions )
      .then( this.onFindSubscribedPlans )
      .then( this.fetchCurrentSite )
  }



  fetchCurrentSite = () => (
    fetchCurrentSite(
      this.state.companySitesProfile.companySiteDesignId,
      this.state.companySiteDesigns
    ).then(site => this.setState({ site })).then(
      this.fetchByodDomainInfoReceived
    )
  );


  setCompliance = companySitesProfile => {
    return this.setState({
      ppCustomHTML: companySitesProfile.privacyPolicyAdditionalHtml || '',
      tosCustomHTML: companySitesProfile.termsOfUseAdditionalHtml || '',
    });
  }


  fetchCompanySitesProfile = () => {
    return Axios.get(
      `/api/sites/company_sites_profiles/${ this.props.match.params.id }`,
      { headers: this.props.getUserRoleAuthHeaders() }
    ).then(response => {
      const companySitesProfile = CompanySitesProfileModel.fromJSON(
        response.data.company_sites_profile
      );

      return this.setState(
        { companySitesProfile },
        () => this.setCompliance( companySitesProfile )
      );
    })
  }


  fetchCompanySitesDesigns = () => {
    return Axios.get(
      `/api/sites/company_site_designs/companies/${ this.props.match.params.id }`,
      { headers: this.props.getUserRoleAuthHeaders() }
    ).then(response => {
      const companySiteDesigns = response.data.company_site_designs.map(
        c => CompanySiteDesignModel.fromJSON( c )
      );

      return this.setState({ companySiteDesigns });
    })
  }


  fetchByodDomainInfoReceived = () => {
    const { site } = this.state;
    const { companyId } = this.state.companySitesProfile;

    return Axios.get(
      `/api/sites/company_sites_profiles/companies/${ companyId }/byod_domain_info_received`,
      { headers: this.props.getUserRoleAuthHeaders() }
    ).then(response => {
      this.setState({ byoDomainInfoReceived: response.data.byod_domain_info_received });
    });
  }

  onUpdateCompanySitesProfile = companySitesProfile => {
    return Axios.put(
      `/api/sites/company_sites_profiles/${ this.state.companySitesProfile.id }`,
      { company_sites_profile: companySitesProfile },
      { headers: this.props.getUserRoleAuthHeaders() }
    ).then(() => this.fetchCompanySitesProfile().then(
        () => {
          this.props.showSnackbar('Company Sites Profile successfully updated.');

          return this.setState({
            dialogOpen: false
          });
        }
      )
    )
  }


  releaseNewDomain = () => {
    const { id, isShutdown, isSetupComplete, newDomain, purchasedDomain } = this.state.companySitesProfile;

    if(
      isSetupComplete &&
      newDomain &&
      purchasedDomain &&
      newDomain === purchasedDomain
    ){
      return Axios.post(
        `/api/sites/company_sites_profiles/${id}/release_new_domain`,
        { },
        { headers: this.props.getUserRoleAuthHeaders() }
      ).then(response => {
        log('undoResubscriptionToSites response', response);

        return this.fetchCompanySitesProfile();
      }).catch(
        () => this.props.showSnackbar('Error releasing the domain.')
      );
    } else {
      ;
      return Promise.reject();
    }
  }


  setIsSSLRequired = () => {
    const { site } = this.state;
    const { id } = this.state.companySitesProfile;

    if( site && !site.isSslRequired ){
      return Axios.put(
        `/api/sites/company_sites_profiles/${ id }`,
        {
          company_sites_profile: {
            is_ssl_required: true
          }
        },
        { headers: this.props.getUserRoleAuthHeaders() }
      ).then( this.fetchCompanySitesProfile );
    } else {
      return Promise.reject()
    }
  }



  toggleMaintenanceMode = () => {
    const { companySitesProfile } = this.state;

    const isInMaintenanceMode = !companySitesProfile.isInMaintenanceMode;
    return Axios.put(
      `/api/sites/company_sites_profiles/${ companySitesProfile.id }/maintenance_mode`,
      {
        is_in_maintenance_mode: isInMaintenanceMode
      },
      { headers: this.props.getUserRoleAuthHeaders() }
    ).then(
      () => this.props.showSnackbar(`Maintenance Mode successfully ${ isInMaintenanceMode ? 'enabled' : 'disabled' }`)
    ).then( this.fetchCompanySitesProfile );
  }


  trackByoDomainInfoReceived = () => {
    const { site } = this.state;
    const { companyId } = this.state.companySitesProfile;

    if( site && !site.publishedAt ){
      return Axios.post(
        `/api/sites/company_sites_profiles/companies/${ companyId }/byod_domain_info_received`,
        { },
        { headers: this.props.getUserRoleAuthHeaders() }
      ).then( this.fetchByodDomainInfoReceived );
    } else {
      return Promise.reject()
    }
  }


  fetchPlans = () => {
    return Axios.get(
      `/api/core/companies/${ this.state.companySitesProfile.companyId }/plans`,
      { headers: this.props.getUserRoleAuthHeaders() }
    ).then(response => {
      const plans = response.data.plans.map(
        p => PlanModel.fromJSON( p )
      );

      return this.setState({ plans });
    })
  }



  fetchSubscriptions = () => {
    return Axios.get(
      `/api/core/companies/${ this.state.companySitesProfile.companyId }/subscriptions`,
      { headers: this.props.getUserRoleAuthHeaders() }
    ).then(response => {
      const subscriptions = response.data.subscriptions.map(
        s => SubscriptionModel.fromJSON( s )
      );

      return this.setState({ subscriptions });
    })
  }



  onFindSubscribedPlans = () => {
    const { plans, subscriptions } = this.state;

    const subscribedPlanIds = subscriptions.map(
      s => +s.planId
    );

    const subscribedPlans = plans.filter(
      p => subscribedPlanIds.includes( +p.id )
    );

    return this.setState({
      subscribedPlans
    });
  }



  toggleSitesQC = () => {
    const { site } = this.state;

    const isQcDisabled = !site.isQcDisabled;
    return Axios.put(
      `/api/sites/${ site.id }/qc`,
      {
        site: { is_qc_disabled: isQcDisabled }
      },
      { headers: this.props.getUserRoleAuthHeaders() }
    ).then(
      () => this.props.showSnackbar(`Sites QC successfully ${ isQcDisabled ? 'disabled' : 'enabled' }`)
    ).then( this.fetchCurrentSite );
  }


  clickCancelEditCompliance = () => {
    this.setCompliance( this.state.companySitesProfile );
  }


  clickUpdateCompliance = () => {
    return this.onUpdateCompanySitesProfile({
      privacy_policy_additional_html: this.state.ppCustomHTML,
      terms_of_use_additional_html: this.state.tosCustomHTML
    });
  }


  clickViewPrivacyPolicy = () => {
    const siteID = hashIdCoder.encode( this.state.site.id );

    const url = `https://privacy.goboost.com/sites/${ siteID }/privacy-policy`;

    window.open(url, '_black');
  }


  clickViewTermsOfUse = () => {
    const siteID = hashIdCoder.encode( this.state.site.id );

    const url = `https://privacy.goboost.com/sites/${ siteID }/terms-of-use`;

    window.open(url, '_black');
  }



  render() {
    const { classes, theme } = this.props;


    const isByoDomain = this.state.companySitesProfile.byoDomain ? true : false;
    const domain = this.state.companySitesProfile.byoDomain || this.state.companySitesProfile.newDomain || '';

    const subscribedSitesPlan = this.state.subscribedPlans.find(
      p => p.isSitesIncluded
    );
    const isBaseSite = subscribedSitesPlan && subscribedSitesPlan.isBaseSite;

    const canEdit = this.props.currentUserRole.roleHasAbility( Ability.EDIT_BYO_DOMAIN );
    const canReleaseDomain = this.props.currentUserRole.roleHasAbility( Ability.RELEASE_NEW_DOMAIN );

    const isShutdown = this.state.companySitesProfile && this.state.companySitesProfile.isShutdown;

    const canTrackByoDomainInfoReceived = (
      this.props.currentUserRole.roleHasAbility( Ability.TRACK_BYO_DOMAIN_INFO_RECEIVED ) &&
      this.state.site && !this.state.site.publishedAt
    );

    const canMarkIsSSLRequired = (
      this.props.currentUserRole.roleHasAbility( Ability.EDIT_SITES_IS_SSL_REQUIRED ) &&
      this.state.companySitesProfile && !this.state.companySitesProfile.isSslRequired
    );

    const isNewDomainNotPurchased = (
      this.state.companySitesProfile &&
      this.state.companySitesProfile.newDomain &&
      (
        this.state.companySitesProfile.newDomain !==
        this.state.companySitesProfile.purchasedDomain
      )
    );

    const isInMaintenanceMode = Boolean(
      this.state.companySitesProfile &&
      this.state.companySitesProfile.isInMaintenanceMode
    );

    const isQCDisabled = Boolean(
      isInMaintenanceMode ||
      (
        this.state.site &&
        this.state.site.isQcDisabled
      )
    );

    return (
      <Grid container spacing={16}>
        <Grid item xs={12}>
          <Typography variant="h5">Site Settings</Typography>
        </Grid>


        <Grid item xs={6}>
          <Paper className={classes.topPaper}>
            <Typography variant="h6">Site Domain</Typography>

            {
              isNewDomainNotPurchased ?
                <Alert danger className={classes.alert}>
                  This domain should be managed by GoBoost but it is not indicated as being purchased. Please provide a BYO domain.
                </Alert>
              :
                null
            }

            <List>
              {
                !this.state.site ?
                  null
                :
                  <ListItem>
                    <ListItemText
                      primary={sitePreviewUrl( this.state.site )}
                      primaryTypographyProps={{
                        color: 'primary',
                        variant: 'h6'
                      }}
                      secondary={`Preview site`}
                    />

                    <ListItemSecondaryAction>
                      <Tooltip title="Visit your domain">
                        <IconButton component="a" target="_blank" href={sitePreviewUrl( this.state.site )}>
                          <OpenInNew/>
                        </IconButton>
                      </Tooltip>
                    </ListItemSecondaryAction>
                  </ListItem>
              }

              <ListItem>
                <ListItemText
                  primary={domain}
                  primaryTypographyProps={{
                    color: 'primary',
                    variant: 'h6'
                  }}
                  secondary={
                    <Grid container spacing={8}>
                      <Grid item xs={12} style={{ marginBottom: 10 }}>
                        { isBaseSite || !isByoDomain ? `GoBoost` : `Company` }-managed domain
                      </Grid>

                      <Grid item xs={4}>
                        <PromiseButton
                          buttonProps={{
                            size: 'small',
                            variant: 'contained',
                            color: 'primary',
                            disabled: (
                              !canTrackByoDomainInfoReceived ||
                              this.state.byoDomainInfoReceived
                            ),
                            fullWidth: true
                          }}
                          onProcess={this.trackByoDomainInfoReceived}
                        >
                          Track Domain Info Received
                        </PromiseButton>
                      </Grid>

                      <Grid item xs={4}>
                        <PromiseButton
                          buttonProps={{
                            size: 'small',
                            variant: 'contained',
                            color: 'secondary',
                            disabled: !canMarkIsSSLRequired,
                            fullWidth: true
                          }}
                          onProcess={this.setIsSSLRequired}
                        >
                          Set SSL Required
                        </PromiseButton>
                      </Grid>

                      <Grid item xs={4}>
                        <PromiseButton
                          buttonProps={{
                            size: 'small',
                            variant: 'contained',
                            color: 'primary',
                            disabled: (
                              !canReleaseDomain ||
                              !this.state.companySitesProfile.purchasedDomain
                            ),
                            fullWidth: true
                          }}
                          onProcess={this.releaseNewDomain}
                        >
                          Release Domain
                        </PromiseButton>
                      </Grid>
                    </Grid>
                  }
                />

                <ListItemSecondaryAction>
                  <Tooltip title="Visit domain">
                    <IconButton component="a" target="_blank" href={`http://${domain}`}>
                      <OpenInNew/>
                    </IconButton>
                  </Tooltip>

                  {
                    (
                      canEdit &&
                      (
                        isByoDomain ||
                        isNewDomainNotPurchased
                      )
                    ) ?
                      (
                        <Tooltip title="Edit domain">
                          <IconButton onClick={() => this.setState({ dialogOpen: true })}>
                            <Edit/>
                          </IconButton>
                        </Tooltip>
                      )
                    :
                      null
                  }
                </ListItemSecondaryAction>

                <EditByoDomainDialog
                  open={this.state.dialogOpen}
                  onClose={() => this.setState({ dialogOpen: false })}
                  companySitesProfile={this.state.companySitesProfile}
                  onUpdateByoDomain={byoDomain => this.onUpdateCompanySitesProfile({ byo_domain: byoDomain })}
                  isNewByoDomain={isNewDomainNotPurchased}
                />
              </ListItem>
            </List>
          </Paper>
        </Grid>

        <Grid item xs={6}>
          <SearchConsoleTag
            tag={this.state.companySitesProfile.searchConsoleTag}
            onEditTag={searchConsoleTag => this.onUpdateCompanySitesProfile({ search_console_tag: searchConsoleTag })}
            canEdit={this.props.currentUserRole.roleHasAbility( Ability.EDIT_COMPANY_SITES_PROFILE )}
          />
        </Grid>


        <Grid item xs={6}>
          <Paper className={classes.topPaper}>
            <Typography variant="h6">Sites Maintenance Mode</Typography>
            <Typography variant="caption">Use the switch below to toggle Maintenance Mode for this company's Site</Typography>

            <List>
              <ListItem>
                <ListItemText
                  primary={
                    <>
                      Maintenance Mode {
                        isInMaintenanceMode ?
                          <span style={{ color: 'red' }}>active</span>
                        :
                          'inactive'
                      }
                    </>
                  }
                />

                <ListItemSecondaryAction>
                  {
                    !this.props.currentUserRole.roleHasAbility( Ability.EDIT_SITES_SITE_MAINTENANCE_MODE ) ?
                      null
                    :
                      <Switch
                        checked={ isInMaintenanceMode }
                        onChange={this.toggleMaintenanceMode}
                        color="primary"
                      />
                  }
                </ListItemSecondaryAction>
              </ListItem>
            </List>
          </Paper>
        </Grid>


        <Grid item xs={6}>
          <Paper className={classes.topPaper}>
            <Typography variant="h6">Sites QC</Typography>

            <Typography variant="caption">Use the switch below to toggle QC for this company's Site</Typography>

            <List>
              <ListItem>
                <ListItemText
                  primary={
                    <>
                      Site QC {
                        isQCDisabled ?
                          <span style={{ color: 'red' }}>disabled</span>
                        :
                          'enabled'
                      }
                    </>
                  }
                />

                <ListItemSecondaryAction>
                  {
                    !this.props.currentUserRole.roleHasAbility( Ability.EDIT_COMPANY_SITES_PROFILE ) ?
                      null
                    :
                      <Switch
                        checked={ !isQCDisabled }
                        onChange={ this.toggleSitesQC }
                        color="primary"
                        disabled={ isInMaintenanceMode }
                      />
                  }
                </ListItemSecondaryAction>
              </ListItem>

              {
                isInMaintenanceMode ?
                  <ListItem>
                    <Typography variant='caption'>
                      Disabled Maintenance Model to enable QC
                    </Typography>
                  </ListItem>
                :
                  null
                }
            </List>
          </Paper>
        </Grid>


        <Grid item xs={6}>
          <Paper className={classes.topPaper}>
            <Typography variant="h6">Sites Compliance</Typography>

            <Typography variant="caption">Use the text areas to add additional custom HTML to the Site's Privacy Policy and Terms of Service</Typography>

            <TextField
              fullWidth
              multiline
              label='Privacy Policy Custom HTML'
              disabled={!this.props.currentUserRole.roleHasAbility( Ability.EDIT_COMPANY_SITES_PROFILE )}
              value={this.state.ppCustomHTML}
              variant='outlined'
              InputProps={{
                style: { fontSize: 15 }
              }}
              style={{ marginTop: 15 }}
              onChange={e => this.setState({ ppCustomHTML: e.target.value })}
            />

            <Button
              variant='outlined'
              color='secondary'
              onClick={ this.clickViewPrivacyPolicy }
              size='small'
              style={{ marginTop: 5, textTransform: 'none' }}
            >
              View Privacy Policy
            </Button>

            <TextField
              fullWidth
              multiline
              label='Terms of Service Custom HTML'
              disabled={!this.props.currentUserRole.roleHasAbility( Ability.EDIT_COMPANY_SITES_PROFILE )}
              value={this.state.tosCustomHTML}
              variant='outlined'
              InputProps={{
                style: { fontSize: 15 }
              }}
              style={{ marginTop: 20 }}
              onChange={e => this.setState({ tosCustomHTML: e.target.value })}
            />

            <Button
              variant='outlined'
              color='secondary'
              onClick={ this.clickViewTermsOfUse }
              size='small'
              style={{ marginTop: 5, marginBottom: 15, textTransform: 'none' }}
            >
              View Terms of Use
            </Button>

            <div style={{ textAlign: 'right', width: '100%', paddingBottom: 15 }}>
              <Button
                variant='outlined'
                color='primary'
                onClick={ this.clickCancelEditCompliance }
                style={{ marginRight: 10 }}
              >
                Cancel
              </Button>

              <Button
                variant='contained'
                color='primary'
                onClick={ this.clickUpdateCompliance }
              >
                Update
              </Button>
            </div>
          </Paper>
        </Grid>
      </Grid>
    );
  }

}

const styles = theme => ({
  paper: {
    padding: 15,
    maxWidth: '100%',
  },
  topPaper: {
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 15,
    maxWidth: '100%',
    minHeight: 125
  },
  button: {
    marginTop: 16,
    float: 'right'
  },
  callTracking: {
    paddingLeft: 15,
    paddingRight: 15,
  },
  alert: {
    marginTop: 15
  }
})

export default withContext(
  ThemeContext,
  SnackbarContext,
  UserRoleContext,
  withStyles(styles)(SiteSettings)
);