import React from 'react';

import { format, withContext, log } from 'kn-react';
import { Link as RouterLink } from 'react-router-dom';
import { TextStatusIndicator, Alert, UserRoleContext, Ability } from 'go-boost-library-react';
import * as d3 from 'd3';

import { AttachMoney, Map, Settings, AccountBalanceWallet, HelpOutline } from '@material-ui/icons';
import { IconButton, Avatar, Switch, Toolbar, Tooltip, withStyles, Typography, Grid } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';

import { CompanyAdsProfileContext } from '../../../CompanyAdsProfile/CompanyAdsProfileProvider';
import { FacebookAccountContext } from 'FacebookAccount/FacebookAccountProvider';
import AdCarousel from '../AdCarousel/AdCarousel';
import BoostedServiceIcon from '../BoostedServiceIcon/BoostedServiceIcon';



// const assetData = {
//   adCarousel: [
//     { image: 'https://storage.googleapis.com/go-boost-partners-public/boosted_service_assets/bs-6-2.jpg', live_url: 'http://localhost:8080/wf/waterfurnace2' },
//     { image: 'https://storage.googleapis.com/go-boost-partners-public/boosted_service_assets/bs-6-3.jpg', live_url: 'http://localhost:8080/wf/waterfurnace3' },
//     { image: 'https://storage.googleapis.com/go-boost-partners-public/boosted_service_assets/bs-6-4.jpg', live_url: 'http://localhost:8080/wf/waterfurnace2' },
//     { image: 'https://storage.googleapis.com/go-boost-partners-public/boosted_service_assets/bs-6-5.jpg', live_url: 'http://localhost:8080/wf/waterfurnace2' },
//   ]
// }

const companyBoostedServicesList = props => {
  const { channels, companyAdsProfile, boostedServices, readyToBoostCompanyBoostedServices, subheader, classes } = props;

  const facebookChannel = channels.find(c => c.isFacebook());

  const sortedCompanyBoostedServices = [...(readyToBoostCompanyBoostedServices || [])];

  sortedCompanyBoostedServices.sort((a, b) => {
    const boostedServiceA = boostedServices.find(s => s.id === a.boostedServiceId);
    const boostedServiceB = boostedServices.find(s => s.id === b.boostedServiceId);
    return d3.ascending(boostedServiceA.name, boostedServiceB.name);
  });

  return (
    <>

      {
        sortedCompanyBoostedServices.map(c => {
          const b = boostedServices.find(b => b.id === c.boostedServiceId);
          const ch = channels.find(c => c.id === b.channelId);


          let textStatusIndicatorProps;
          let boostBalanceText;
          let alert;

          // Boost Zone Text
          const includedGeoCount = c.boostZone.includedGeographies.length;
          const excludedGeoCount = c.boostZone.excludedGeographies.length;

          let boostZoneText = (
            includedGeoCount ?
              `${includedGeoCount} location${includedGeoCount === 1 ? '' : 's'} included, ${excludedGeoCount} location${excludedGeoCount === 1 ? '' : 's'} excluded`
              :
              'Not configured'
          );


          let boostStrategyText = c.boostStrategyName || '';
          boostStrategyText = boostStrategyText.slice(0, 1).toUpperCase() + boostStrategyText.slice(1);

          if( c.boostStrategyName === 'monthly' ){
            const amount = ( ( +c.monthlyMaxCents || 0 ) / 100 ).toFixed(2);
            boostStrategyText += ` - $${ amount } monthly`
          } else if( c.boostStrategyName === 'aggressive' ){
            const amount = ( ( +c.autoRenewAmountCents || 0 ) / 100 ).toFixed(2);
            boostStrategyText += ` - $${ amount } auto-renew`
          }


          if (c.boostBalanceCents === null) {
            if(
              ch.isFacebook() && !c.isSetupComplete &&
              ( !props.facebookAccountPage || !props.facebookAccountPage.id )
            ){
              boostBalanceText = `${format.money(c.initialBoostTotalCents / 100)}`;
              alert = {
                danger: true,
                children: (
                  <RouterLink
                    to={ `/ads/setup/boosted_services/${b.id}/facebook_account_setup` }
                  >
                    Connect your Facebook Account to launch this Boosted Service
                  </RouterLink>
                )
              };
            } else if (boostStrategyText) {
              boostBalanceText = `${format.money(c.initialBoostTotalCents / 100)}`;
              textStatusIndicatorProps = {
                success: true,
                text: 'Ready to Boost'
              };
            } else {
              boostStrategyText = 'Strategy not configured';
              boostBalanceText = 'Not configured';
              textStatusIndicatorProps = {
                text: 'Not configured'
              };
            }
          } else {
            if(
              ch.isFacebook() && !c.isSetupComplete &&
              ( !props.facebookAccountPage || !props.facebookAccountPage.id )
            ){
              alert = {
                danger: true,
                children: (
                  <RouterLink
                    to={ '/ads/settings#connected-accounts' }
                  >
                    Connect your Facebook Account to launch this Boosted Service
                  </RouterLink>
                )
              };
            } else if (!c.isActivated) {
              // Include this here so that shut down profiles show inactive ads
              textStatusIndicatorProps = {
                text: 'Inactive'
              };
            } else if (!c.isSetupComplete) {
              textStatusIndicatorProps = {
                warning: true,
                text: 'Bots are building ads. Live within 24 hours.'
              };
            } else if( c.budgetTooLow ){
              alert = {
                danger: true,
                children: (
                  <RouterLink
                    to={ `/ads/boosted_services/${ c.boostedServiceId }/add_to_balance` }
                  >
                    <Typography variant='body2'>
                      The budget for this campaign is too low. Please add more money to the Boost Balance to run this campaign.
                    </Typography>
                  </RouterLink>
                )
              };
            } else {
              textStatusIndicatorProps = {
                success: true,
                text: 'Live',
              };
            }

            boostBalanceText = `${format.money(c.boostBalanceCents / 100)} balance as of ${format.date(c.boostBalanceUpdatedAt)}`;

          }

          const assetData = b.assetData || {};

          let adCarousel;
          if (assetData.adCarousel) {
            adCarousel = assetData.adCarousel.map(d => ({
              image: d.image,
              url: d.live_url + `?co=${c.companyId}&bs=${b.id}&ch=${ch.name}`,
            }));
          }

          const isShutdown = props.companyAdsProfile && companyAdsProfile.isShutdown;

          return (
            <Grid item xs={12} key={c.id}>
              <Card>

                <CardHeader
                  className={classes.cardHeader}
                  disableTypography
                  title={
                    <Typography variant="subtitle1">{b.name}</Typography>
                  }
                  subheader={
                    <>
                      <Typography variant="caption" color="textSecondary">
                        {ch.name}
                      </Typography>

                      {
                        alert ?
                          <Alert {...alert} />
                        :
                          <TextStatusIndicator {...textStatusIndicatorProps} />
                      }

                      {
                        !ch.isGoogleSearch() ?
                          null
                        :
                          <Alert warning style={{ marginTop: 10 }}>
                            Please reach out to your <a href="mailto:account_managers@goboost.com">Account Manager</a> or <a href="mailto:support@goboost.com">Support Team</a> for any changes to this Google Search Boosted Service.
                          </Alert>
                      }
                    </>
                  }
                  avatar={
                    <Hidden xsDown>
                      <BoostedServiceIcon
                        channels={channels}
                        boostedService={b}
                      />
                    </Hidden>
                  }
                  action={
                    <Toolbar disableGutters>
                      {
                        b.channelId == facebookChannel.id || b.surveyId ?
                          <Tooltip title={<div>Edit Boosted Service Settings</div>} enterDelay={500}>
                            <IconButton onClick={() => props.onClickEditSurvey(b)}>
                              <Settings />
                            </IconButton>
                          </Tooltip>
                          : null
                      }

                      {
                        props.onToggle && !isShutdown && props.currentUserRole.roleHasAbility( Ability.EDIT_COMPANY_BILLING ) ?
                            <Tooltip title="Turn your ads on or off" enterDelay={500}>
                              <Switch
                                onChange={e => props.onToggle(e, c)}
                                checked={c.isActivated}
                                color="primary"
                              />
                            </Tooltip>
                          :
                            isShutdown && props.currentUserRole.roleHasAbility( Ability.EDIT_COMPANY_BILLING ) ?
                              <Tooltip
                                title={`Complete setup then navigate to "Boosted Services" to reactivate this Boosted Service.`}
                              >
                                <HelpOutline color="primary" />
                              </Tooltip>
                            :
                              null
                      }
                    </Toolbar>
                  }
                />

                {
                  adCarousel ?
                    <AdCarousel data={adCarousel} />
                    : null
                }

                <List dense>
                  <ListItem button onClick={() => props.onClickEditBoostZone(b)}>
                    <ListItemAvatar>
                      <Avatar >
                        <Map />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="Boost Zone"
                      primaryTypographyProps={{
                        color: 'primary'
                      }}
                      secondary={boostZoneText}
                      secondaryTypographyProps={{
                        color: 'textPrimary'
                      }}
                    />
                  </ListItem>


                  <ListItem button onClick={() => props.onClickAddToBalance(b)}>
                    <ListItemAvatar>
                      <Avatar >
                        <AttachMoney />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="Boost Balance"
                      primaryTypographyProps={{
                        color: 'primary'
                      }}
                      secondary={boostBalanceText}
                      secondaryTypographyProps={{
                        color: 'textPrimary'
                      }}
                    />
                  </ListItem>


                  <ListItem button onClick={() => props.onClickEditBoostStrategy(b)}>
                    <ListItemAvatar>
                      <Avatar>
                        <AccountBalanceWallet />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="Boost Strategy"
                      primaryTypographyProps={{
                        color: 'primary'
                      }}
                      secondary={boostStrategyText}
                      secondaryTypographyProps={{
                        color: 'textPrimary'
                      }}
                    />
                  </ListItem>
                </List>

              </Card>
            </Grid>
          );
        })
      }
    </>
  );
};



companyBoostedServicesList.defaultProps = {
  onClickAddToBalance: () => {},
  onClickEditSurvey: () => {},
  onClickEditBoostZone: () => {},
  onToggle: undefined, // Falsey means the toggleButton won't be shown.
}


const styles = theme => ({
  cardHeader: {
    borderBottom: '1px solid #efefef',
  },
});


export default withStyles(styles)(
  withContext(
    UserRoleContext,
    CompanyAdsProfileContext,
    FacebookAccountContext,
    companyBoostedServicesList
  )
);
